import { gsap } from 'gsap';

window.onload = (event) => {
  const confettiTrigger = $('#confetti-trigger');

  confettiTrigger.click(function () {
    const tl = gsap.timeline(
      {
        repeat: 1,
        yoyo: true,
      },
      {}
    );

    tl.to('#confetti-emoji', {
      opacity: 1,
      scaleX: 1.8,
      scaleY: 1.8,
      rotate: 7,
      ease: 'none',
    });

    tl.to('#confetti-emoji', {
      opacity: 1,
      scaleX: 1.8,
      scaleY: 1.8,
      rotate: -7,
      ease: 'none',
    });

    tl.to('#confetti-emoji', {
      opacity: 1,
      scaleX: 1.8,
      scaleY: 1.8,
      rotate: 7,
      ease: 'none',
    });
    // jsConfetti.addConfetti({
    //     confettiColors: [
    //         '#0D0C91', '#FC47DF', '#02B9E1', '#ff85a1', '#FFE600', '#FFE600',
    //     ],
    // })
  });
};
