const buttons = document.querySelectorAll('.open-accordion');

if (buttons) {
  buttons.forEach((button) => {
    const content = button.nextElementSibling;

    button.onclick = () => {
      const openAccordions = document.querySelectorAll('.accordion .show');

      if (content.classList.contains('show')) {
        openAccordions.forEach((openAccordion) => {
          openAccordion.classList.remove('show');
          buttons.forEach((button) => {
            button.classList.remove('rotate');
          });
        });
      } else {
        openAccordions.forEach((openAccordion) => {
          openAccordion.classList.remove('show');
          buttons.forEach((button) => {
            button.classList.remove('rotate');
          });
        });
        setTimeout(() => {
          content.classList.add('show');
          button.classList.add('rotate');
        }, 100);
      }
    };
  });
}
