import {gsap} from 'gsap'

let windowWidth = window.innerWidth;

gsap.set(' .hero .top-section .top-text-wrapper *', {autoAlpha: 0, y: '1rem'});
gsap.set(' .image-gallery li', {autoAlpha: 0.3, scale: '0.9', y: '1rem'});
gsap.set(' .pointers li img ', {autoAlpha: 0, y: '0rem'});

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


const animateVisible = (block, ratio, isIntersecting) => {
    // When the element is in view:
    if (ratio > 0 && isIntersecting) {
        // Animate all the children of this element
        switch (block.dataset.animation) {
            case 'hero':
                gsap.to(block.querySelectorAll(' .top-section .top-text-wrapper *'), {
                    duration: 0.5,
                    // animate back to visible
                    autoAlpha: 1,
                    // animate back to top
                    y: '0',
                    stagger: 0.2,

                    ease: 'circ.easeOut',
                })
                break;
            case "image-gallery":
                gsap.to(block.querySelectorAll('li'), {
                    duration: 0.8,
                    // animate back to visible
                    autoAlpha: 1,
                    // animate back to top
                    y: '0',
                    stagger: 0.2,
                    scale: 1,

                    ease: 'circ.easeOut',
                })
                break;
            /*
        case "kpi" :
            const items = block.querySelectorAll(".kpi-int");
            gsap.from(items, {
                textContent: 0,
                duration: 2.5,
                ease: "power1.in",
                snap: {textContent: 1},
                stagger: {
                    each: 0.4,
                    onUpdate: function () {
                        this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent));
                    },
                }
            });
            break;

             */
            case "pointers" :
                gsap.to(block.querySelectorAll('img'), {
                    duration: 0.2,
                    autoAlpha: 1,
                    y: '0',
                    stagger: 0.15,
                    ease: 'power1.in'

                });
                break;
        }

    }
}
//check the sections


const blocks = document.querySelectorAll(' #content-section section');
const blocksObserver = new IntersectionObserver(
    entries => {
        // entries === elements
        return entries.forEach(event => {
            const {target, intersectionRatio, isIntersecting} = event;
            // Call function
            animateVisible(target, intersectionRatio, isIntersecting);
        });
    },
    //trigger only when above threshold
    {threshold: 0.2}
);
for (const block of blocks) {
    blocksObserver.observe(block);
}