var $menuIcon = $('header .menu-wrapper .menu-button');
var $header = $('header');
var $body = $('body');
let $removeBg = $('remove-bg');
let $menuText = document.getElementById('menu-text');

var $navMenu = $('header .menu-wrapper .nav-menu');

if ($body.css('background-color') === 'rgb(242, 255, 252)') {
  $('.sub-menu').each(function () {
    console.log($(this).html());
    $(this).css('background-color', '#F2FFFC');
  });
}

if ($menuIcon) {
  $menuIcon.click(function () {
    if ($menuIcon.hasClass('menu-open')) {
      $menuIcon.removeClass('menu-open');
      $header.removeClass('show-menu');
      $body.removeClass('no-scroll-y');
      $menuText.textContent = 'Meny';
      if ($header.hasClass('remove-bg')) {
        $header.removeClass('bg-blue');
      }

      setTimeout(function () {
        $navMenu.css({
          'max-height': '0',
          display: 'none',
        });
      }, 500);
    } else {
      $menuIcon.addClass('menu-open');

      $header.addClass('bg-blue');
      $body.addClass('no-scroll-y');
      $menuText.textContent = document.documentElement.lang === 'sv-SE' ? 'Stäng' : 'Lukk';
      $navMenu.css({
        'max-height': 'calc(var(--vh, 1vh) * 100 - 5rem)',
        display: 'block',
      });

      setTimeout(function () {
        $header.addClass('show-menu');
      }, 100);
    }
  });
}

$(window).resize(function () {
  if ($(window).width() >= 1300) {
    $('header').removeClass('show-menu');
  }
});

var $childrenMenuLink = $(
  'header .menu-wrapper .nav-menu .menu-content ul li.menu-item-has-children .link-wrapper >a'
);
var $submenus = $(
  'header .menu-wrapper .nav-menu .menu-content ul li.menu-item-has-children .sub-menu'
);
var $submenuArrow = $(
  'header .menu-wrapper .nav-menu .menu-content ul li.menu-item-has-children .submenu-arrow'
);
var $submenuLink = $(
  'header .menu-wrapper .nav-menu .menu-content ul li.menu-item-has-children ul.submenu li a'
);

// function link() {
//   const parent = $(this).closest('li');
//   var $submenu = parent.find('.sub-menu');

//   if ($(this).closest('li').hasClass('submenu-open')) {
//     setTimeout(function () {
//       $submenu.css({
//         display: 'none',
//       });
//     }, 500);

//     $(this).closest('li').removeClass('submenu-open');

//     const url = this.href;

//     let slug = (url) => new URL(url).pathname.match(/[^\/]+/g);
//     // console.log(slug(url));

//     if (slug(url) != null) {
//       window.location = $(this).attr('href');
//     }
//   } else {
//     const list = $(this).closest('li');
//     $submenu.css({
//       display: 'block',
//     });
//     setTimeout(function () {
//       list.addClass('submenu-open');
//     }, 100);
//   }
// }

// $childrenMenuLink.click(function (e) {
//   e.preventDefault();
//   const parent = $(this).closest('li');
//   var $submenu = parent.find('.sub-menu');

//   if ($(this).closest('li').hasClass('submenu-open')) {
//     setTimeout(function () {
//       $submenu.css({
//         display: 'none',
//       });
//     }, 500);

//     $(this).closest('li').removeClass('submenu-open');

//     const url = this.href;
//     //console.log(url)
//     let slug = (url) => new URL(url).pathname.match(/[^\/]+/g);
//     // console.log(slug(url));

//     if (slug(url) != null) {
//       window.location = $(this).attr('href');
//     }
//   } else {
//     const list = $(this).closest('li');
//     $submenu.css({
//       display: 'block',
//     });
//     setTimeout(function () {
//       list.addClass('submenu-open');
//     }, 100);
//   }
// });

// $submenuArrow.click(function (e) {
//   const parent = $(this).parent('.link-wrapper');
//   var $submenu = parent.siblings('.sub-menu');
//   if ($(this).closest('li').hasClass('submenu-open')) {
//     setTimeout(function () {
//       $submenu.css({
//         display: 'none',
//       });
//     }, 500);

//     $(this).closest('li').removeClass('submenu-open');
//   } else {
//     const list = $(this).closest('li');
//     $submenu.css({
//       display: 'block',
//     });
//     setTimeout(function () {
//       list.addClass('submenu-open');
//     }, 100);
//   }
// });

// $submenuLink.click(function (e) {
//   $(this).parent().removeClass('submenu-open');
//   $menuIcon.removeClass('menu-open');
//   $header.removeClass('show-menu');
//   $body.removeClass('no-scroll-y');
// });

//hover for desktop
// if ($(window).width() >= 1200) {
//   $childrenMenuLink.mouseleave(function (e) {
//     const parent = $(this).closest("li");
//     var $submenu = parent.find(".sub-menu");
//     const list = $(this).closest("li");
//     setTimeout(function () {
//       if (parent.find(".sub-menu").is(":hover")) {
//         // console.log("hover");
//       } else {
//         //  console.log("no hover",     $(this).closest("li"));

//         list.removeClass("submenu-open");

//         setTimeout(function () {
//           $submenu.css({
//             display: "none",
//           });
//         }, 500);
//       }
//     }, 500);
//   });
//   $childrenMenuLink.mouseenter(function (e) {
//     const parent = $(this).closest("li");
//     var $submenu = parent.find(".sub-menu");

//     const list = $(this).closest("li");
//     $submenu.css({
//       display: "block",
//     });
//     setTimeout(function () {
//       list.addClass("submenu-open");
//     }, 100);
//   });

//   $submenus.hover(function (e) {
//     if ($(this).closest("li").hasClass("submenu-open")) {
//       $(this).closest("li").removeClass("submenu-open");

//       setTimeout(function () {
//         $(this).css({
//           display: "none",
//         });
//       }, 100);
//     }
//   });
// }
