const openModalBtns = document.querySelectorAll('.open-modal');
const closeModalBtns = document.querySelectorAll('.close-modal');

function modalStyle(modal) {
  if (modal.classList.contains('open')) {
    modal.style.opacity = '0';

    setTimeout(() => {
      modal.classList.remove('open');
    }, '500');
  } else {
    modal.classList.add('open');
    setTimeout(() => {
      modal.style.opacity = '1';
    }, '100');
  }
}

if (openModalBtns) {
  openModalBtns.forEach((openModalBtn) => {
    const parent = openModalBtn.parentElement;
    const modal = parent.nextElementSibling;

    openModalBtn.addEventListener('click', function () {
      modalStyle(modal);
    });
  });
}

if (closeModalBtns) {
  closeModalBtns.forEach((closeModalBtn) => {
    const innerModal = closeModalBtn.parentElement;
    const modal = innerModal.parentElement;

    closeModalBtn.addEventListener('click', function () {
      modalStyle(modal);
    });

    modal.addEventListener('click', function () {
      modalStyle(modal);
    });
  });
}
