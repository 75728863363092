import './_body-view-height';

import './_menu.js';
import './_menu-scroll.js';
import './_intersection-observer.js';
import './_scroll-to.js';
import './_offices.js';
import './_block-support.js';
import './_tab-menu.js';
import './_block-price-table.js';
import './_block-accordion.js';
import './_inspiration-archive.js';
import './_filterButtons.js';
import './_confetti.js';
import './_search.js';

//skip header
let skipHeader = document.querySelector('a.visually-hidden.skip-to-main');
if (skipHeader != undefined) {
  skipHeader.addEventListener('focusin', function (e) {
    this.classList.add('focused');
  });
  skipHeader.addEventListener('focusout', function (e) {
    this.classList.remove('focused');
  });
}

