const $support = $('.block-support');
const $supportAvailable = $support.find('.support-available');
if ($support.length > 0) {
    reloadSupportReps();
}

function reloadSupportReps() {
    const data = new FormData();
    data.append('action', 'get_support_reps');

    $.ajax({
        url: upheads_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function(error) {
            console.error(error);
        },
        complete: function(data) {
            console.log(data.responseText);
            $supportAvailable.html(data.responseText);

            $supportAvailable.find('.reload-support-reps').click(function(e) {
                e.preventDefault();

                $supportAvailable.html('<div class="loading-spinner"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
                reloadSupportReps();
            });
        }
    });
}
