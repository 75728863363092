const url = window.location.href


//window on load thingie?
$(document).ready(function () {
    setTimeout(function () {
        if (window.location.href.slice(-1) == '?') {
            console.log('if-statement fired')
            var form = $("#upheads-form");
            $('html, body').animate({
                scrollTop: form.offset().top - (120 || 0)
            }, 300);

        }
    }, 300)
})


// Smooth scroll to anchor links
$('a').click(function (e) {
    scrollToAnchor(this.href, e, 120);
});


function scrollToAnchor(href, event, offset) {
    if (href.length <= 1) {
        return;
    }

    var hashIndex = href.indexOf('#');

    if (hashIndex === -1) {
        return;
    }

    var currentPath = window.location.origin + window.location.pathname;
    /*   if (hashIndex > 0 && href.substring(0, hashIndex) !== currentPath) {
          return;
      } */

    var id = href.substring(hashIndex + 1);

    if (!id) {
        return;
    }

    var $el = $('#' + id);

    if ($el.length === 0) {
        return;
    }

    $('html, body').animate({
        scrollTop: $el.offset().top - (offset || 0)
    }, 300);

    if (event) {
        event.preventDefault();
        window.location.hash = id;
    }
}

window.scrollToAnchor = scrollToAnchor;