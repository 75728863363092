let searchIcon = document.querySelector('header button.search-btn');
let searchPage = document.querySelector('body.search');

if (searchIcon) {
    let search = document.querySelector('#search');
    let searchInput = document.querySelector('#search #search-input');
    searchIcon.addEventListener('click', function (e) {
        e.preventDefault();
        if(search.classList.contains('expand')) {
            search.classList.remove('expand');
            searchIcon.querySelector('p').textContent = document.documentElement.lang === 'sv-SE' ? 'Sök' : 'Søk';
        }
        else {
            search.classList.add('expand');
            searchInput.focus();
            searchIcon.querySelector('p').textContent = document.documentElement.lang === 'sv-SE' ? 'Stäng' : 'Lukk';
        }
    });

    if(search) {
        let deleteBtn = search.querySelector('svg');

        if(searchInput.value != '') {
            deleteBtn.classList.add('show');
        }
    
        searchInput.addEventListener('keyup', function (e) {
            let searchText = e.target.value.toLowerCase();
            if(searchText != '') {
                deleteBtn.classList.add('show');
            }
            else {
                deleteBtn.classList.remove('show');
            }
        });
        deleteBtn.addEventListener('click', function(e) {
            searchInput.value = '';
            deleteBtn.classList.remove('show');
        });
    }
}

if (searchPage) {
    let pageSearchInput = searchPage.querySelector('#search-page #search-page-input');
    let inputValue = searchPage.querySelector('#content-section #search-page #search-page-input').value;
    let deleteSearchBtn = searchPage.querySelector('#search-page svg');

    pageSearchInput.focus();
    pageSearchInput.value = '';
    pageSearchInput.value = inputValue;
        
    if(pageSearchInput.value != '') {
        deleteSearchBtn.classList.add('show');
    }

    pageSearchInput.addEventListener('keyup', function (e) {
        let searchText = e.target.value.toLowerCase();
        if(searchText != '') {
            deleteSearchBtn.classList.add('show');
        }
        else {
            deleteSearchBtn.classList.remove('show');
        }
    });
    deleteSearchBtn.addEventListener('click', function(e) {
        pageSearchInput.value = '';
        deleteSearchBtn.classList.remove('show');
    });
}
