const categoryBtn = document.querySelector('.open-categories');
const subjectBtn = document.querySelector('.open-subjects');

function openFilter(button) {
  const list = button.nextElementSibling;

  let isOpen = list.classList.contains('open');

  if (isOpen) {
    list.classList.remove('open');

    list.style.opacity = '0';
  } else {
    list.style.opacity = '1';
    setTimeout(() => {
      list.classList.add('open');
    }, 10);
  }
}
if (categoryBtn) {
  categoryBtn.onclick = function () {
    openFilter(categoryBtn);
  };
}

if (subjectBtn) {
  subjectBtn.onclick = function () {
    openFilter(subjectBtn);
  };
}
