window.addEventListener("scroll", colorMenu);

let menu = $("#upheads-menu");
let transparent = $(".transparent-menu");
let body = $("body");
let heroImage = $(".hero .image-wrapper");
let whiteText = false;
if (heroImage && heroImage.length > 0) {
  menu.addClass("white-text");
  whiteText = true;
}

function colorMenu() {
  if (menu) {
    if (window.scrollY > 16 && window.innerWidth < 1300) {
      menu.css({
        position: "fixed",
        "padding-top": "1rem",
      });
    } else if (window.innerWidth < 1300) {
      menu.css({
        "padding-top": "2rem",
        position: "absolute",
      });
    }
    if (transparent.length && !body.hasClass("error404")) {
      if (window.pageYOffset >= 20) {
        menu.css("background-color", "#8481E8");
      } else if (window.pageYOffset < 20) {
        menu.css("background-color", "transparent");
      }
    } else {
      if (window.pageYOffset >= 20) {
        if (body.css("background-color") === "rgb(242, 255, 252)") {
          menu.css("background-color", "#F2FFFC");
          $("#white").removeClass("white").addClass("blue");
          if (whiteText) {
            menu.removeClass("white-text");
          }
        }
        else if (body.css("background-color") === "rgb(255, 240, 204)") {
          menu.css("background-color", "#FFF0CC");
          $("#white").removeClass("white").addClass("blue");
          if (whiteText) {
            menu.removeClass("white-text");
          }
        } else {
          menu.css("background-color", "#E3E5DA");
          $("#white").removeClass("white").addClass("blue");
          if (whiteText) {
            menu.removeClass("white-text");
          }
        }
      } else if (window.pageYOffset < 20) {
        menu.css("background-color", "transparent");
        $("#white").removeClass("blue").addClass("white");
        if (whiteText) {
          menu.addClass("white-text");
        }
      }
    }
  }
}
