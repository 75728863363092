/*
 * Calculate visible view height.
 * On Mobile Safari, 100vh is equal to the whole view as it extends underneath the bottom bar.
 * With this solution, 100 calculated --vh units is the height of the visible view

 * In CSS, use the following to get the height:
 *   height: 100vh;
 *   height: calc(var(--vh, 1vh) * 100);
 */
const setupBodyViewHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}
setupBodyViewHeight();
window.addEventListener('resize', setupBodyViewHeight);
